import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faEnvelope, faStar } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../Services/AuthService';
import FileService from '../../Services/FileService';
import { useHistory } from 'react-router';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import Masonry from 'react-masonry-component';
import { EventService } from '../../Services/EventService';

const Company = () => {

    const user = JSON.parse(localStorage.getItem('digitalEventUser'));
    const [company, setCompany] = useState({});
    const { companyId } = useParams();
    const [showingLightbox, setShowingLightbox] = useState(false);
    const [initImage, setInitImage] = useState(0);
    const [active, setActive] = useState('profile');
    const [event, setEvent] = useState({});
    const [activities, setActivities] = useState([]);
    const authService = new AuthService();
    const fileService = new FileService();
    const history = useHistory();
    const eventService = new EventService();
    const masonryOptions = {
        transitionDuration: 0
    };

    if(!user){
        return <Redirect to="/networking/login" />
    }

    useEffect(() => {
        authService.getCompany(companyId).then(r => setCompany(r));
        eventService.getEvent(1).then(r => {
            setEvent(r);
            setActivities(JSON.parse(r.companyActivities));
        })
    }, []);

    const arrangeAppointment = (userId) => {
        history.push(`/networking/appoint/${userId}`);
    }

    return <>
        <Row>
            <Col md="12" style={{ marginLeft: '10px', marginTop: '25px', marginBottom: '30px' }}>
                <h4 className="text-primary bold" style={{fontWeight: '900', fontSize: '28px'}}>{ company.title } &gt; Profile</h4>
            </Col>
        </Row>
        <Row>
            
            <Col md="12">
                
                <br />
                <Card>
                    
                    
                        <h3 className="text-primary">Users</h3>
                        <hr />
                        <Row>
                        {
                            company && company.users && company.users.map(p => {
                                return <Col md="3" key={p.userId}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="3">
                                                    <img src="/pic.png" alt="profile" style={{ height: '60px' }} />
                                                </Col>
                                                <Col md="9">
                                                    <span className="float-end text-primary" style={{ fontSize: '25px' }}><FontAwesomeIcon icon={faBookmark} /></span>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '5px' }}>
                                                <Col md="12">
                                                    <h6>{p.name}</h6>
                                                    <p>{p.roleInCompany}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    {
                                                        user.userId !== p.userId && <>
                                                            <Button onClick={() => arrangeAppointment(p.userId)} color="warning" size="sm" style={{ marginRight: "10px" }}>Meet</Button>
                                                            <Button size="sm" color="primary" onClick={() => history.push(`/networking/profile/${p.userId}`)}>View Profile</Button>
                                                        </>
                                                    }
                                                    
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    
                                </Col>
                            })
                        }
                        </Row>
                    
                </Card>
            </Col>
        </Row>
    </>;
};

export default Company;