import React, { useState, useEffect, Fragment } from 'react';
import { AuthService } from '../../Services/AuthService';
import { EventService } from '../../Services/EventService';
import { Redirect } from 'react-router';
import { Row, Col, Card, Form, FormGroup, Input, Label, Button, Alert, Table, CardBody, NavLink } from 'reactstrap';
import { MyLibrary } from '../../Services/MyLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faEdit, faPlusSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import FileService from '../../Services/FileService';
import DateTimePicker from 'react-datetime-picker';
import useForceUpdate from 'use-force-update';
import axios from 'axios';


const Stage = () => {
    const [event, setEvent] = useState({});
    const [html, setHtml] = useState('');

    const eventService = new EventService();

    useEffect(() => {
        axios.get('/presentation.html' ).then(r => {
            console.log(r.data);
            setHtml(r.data)
        });
    }, [])

    // useEffect(() => {
    //     eventService.getEvent(1).then(r => setEvent(r));
    // }, [])

    // return <Fragment >
    //     <div className="mycontainer">  
    //         {
    //             event.eventYoutubeLink && <iframe width="560" height="315" src={event.eventYoutubeLink.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')} 
    //             title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
    //         }
    //     </div>
    // </Fragment>

    return <Fragment >
        {
            html && <div dangerouslySetInnerHTML={{__html: html}}></div>
        }
        {
            !html && <h3>Loading...</h3>
        }
    </Fragment>
}

export default Stage;