import React, { useState, useEffect } from 'react';
import { EventService } from '../../Services/EventService';
import { Row, Col, Card, CardBody, Button, Input } from 'reactstrap';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import FileService from '../../Services/FileService';

const Networking = () => {
    const eventsService = new EventService();
    const [event, setEvent] = useState(null);
    const [search, setSearch] = useState('');
    const [participants, setParticipants] = useState([]);
    const user = JSON.parse(localStorage.getItem('digitalEventUser'));
    const history = useHistory();
    const fileService = new FileService();

    if(!user){
        return <Redirect to="/networking/login" />
    }
    const arrangeAppointment = (userId) => {
        history.push(`/networking/appoint/${userId}`);
    }

    const getCompanyName = (data) => {
        if(event){
            let userData = JSON.parse(data);
            return userData && userData["Company Name"] ? userData["Company Name"] : '';
        }
    };

    useEffect(() => {
        eventsService.getEvent(1).then(r => setEvent(r)).catch(e => console.log('no event'));
        eventsService.getParticipants(1).then(r => setParticipants(r)).catch(e => console.log('no participants'));
    }, []);

    const getUserRole = (theuser) => {
        if(event && event.dataToComplete){
            let eventData = JSON.parse(event.dataToComplete);
            if(eventData){
                let userData = JSON.parse(theuser.data);
                if(userData){
                    for(let i = 0; i < eventData.length; i ++){
                        if(eventData[i].type === 'Role' && userData){
                            return userData[eventData[i].name] ? userData[eventData[i].name] : '';
                        }
                    }
                }
            }
        }
        return '';
    }

    return <div style={{margin: '20px'}}>
        <Input type="search" className="mycustomSearch2 float-end" onChange={(e) => setSearch(e.target.value)} placeholder="Search Participants"/>
        <h3 className="text-primary" style={{ margin: '20px' }}>List of Users</h3>
        <Row>
            {
                participants.filter(p => p.companyId === 2 && (!user || p.userId !== user.userId) && (p.name.includes(search) || p.email.includes(search) )).map(p => {
                    return <Col md="3" key={p.userId} style={{marginTop: '30px'}}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        <img src={p.photo ? fileService.getUrl(JSON.parse(p.photo)[0].name) : "/pic.png"} alt="profile" style={{ height: '60px' }} />
                                    </Col>
                                    <Col md="9" className="text-end">
                                        {
                                            getUserRole(p) && <Button size="sm" color="success">{getUserRole(p)}</Button>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col md="12">
                                        <h6>{p.name}</h6>
                                        <p>{p.bio}</p>
                                        <h6 className="text-primary">{getCompanyName(p.data)}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    { p.companyId !== 2 && 
                                    <Col md="12">
                                        <NavLink to={`/company/${p.companyId}`} style={{ textDecoration: 'none' }}>
                                            <h4 className="text-warning">{p.company.title}</h4>
                                        </NavLink>
                                        <p>{p.company.city}, {p.company.country}</p>
                                    </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col md="9" className="text-end">
                                        <Button onClick={(e) => arrangeAppointment(p.userId)} color="warning" size="sm" style={{ marginRight: "10px" }}>Meet</Button>
                                        <Button size="sm" color="primary" onClick={() => history.push(`/networking/profile/${p.userId}`)}>View Profile</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        
                    </Col>
                })
            }
            
        </Row>
    </div>

};

export default Networking;