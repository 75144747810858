import React, { useState, useEffect } from 'react';
import { EventService } from '../../Services/EventService';
import { AppointmentService } from '../../Services/AppointmentService';
import { Redirect, useParams } from 'react-router';
import { Col, Row, Card, CardBody, Table, Button, Modal, ModalBody, ModalHeader, ModalFooter, 
        Label, FormGroup, Input, Alert } from 'reactstrap';
import { MyLibrary } from '../../Services/MyLibrary';
import { v4 } from 'uuid';
import useForceUpdate from 'use-force-update';
import timezones from 'timezones-list';

const ArrangeMeeting = () => {
    const appointmentService = new AppointmentService();
    const eventService = new EventService();
    const [appointments, setAppointments] = useState([]);
    const [event, setEvent] = useState({});
    const [myAppointments, setMyAppointments] = useState([]);
    const { otherUserId } = useParams();
    const [participants, setParticipants] = useState([]);
    const [participant, setParticipant] = useState({}); 
    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);
    const [unavailableHours, setUnavailableHours] = useState([]);
    const [unavailableHours2, setUnavailableHours2] = useState([]);
    const [setter, setSetter] = useState(0);
    const [showMeetingModal, setShowMeetingModal] = useState(false);
    const [requesting, setRequesting] = useState('');
    const [message, setMessage] = useState('');
    const [timezone, setTimezone] = useState('Europe/Athens');
    const [myTimezone, setMyTimezone] = useState('');
    const [showMyTimezone, setShowMyTimezone] = useState(false);
    const user = JSON.parse(localStorage.getItem('digitalEventUser'));
    const [needToCompleteData, setNeedToCompleteData] = useState(false);
    const forceUpdate = useForceUpdate();
    const dds = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    if(!user){
        return <Redirect to="/networking/login" />
    }
    const toggleMeetingModal = () => {
        setShowMeetingModal(!showMeetingModal);
    }

    useEffect(() => {
        eventService.getParticipants(1).then(r => {
            setParticipants(r);
            setParticipant(r.filter(p => p.userId === parseInt(otherUserId))[0]);
        }).catch(e => console.log('error fetching'));
        eventService.getEvent(1).then(ev => {
            setEvent(ev);
            if(ev.timezone){
                setTimezone(ev.timezone);
            }
            if(user && ev){
                let userData = JSON.parse(user.data);
                let eventData = JSON.parse(ev.dataToComplete);
                for(let i = 0; i < eventData.length; i ++){
                    let td = eventData[i];
                    if(!userData || !userData[td.name] ){
                        setNeedToCompleteData(true);
                    }
                }
                if(!user.bio){
                    setNeedToCompleteData(true);
                }
            }
            checkTimezone(ev)
            console.log(ev)
            calculateAvailability(ev);
        });
        if(user){
            appointmentService.getAppointments(otherUserId).then(r => {
                setAppointments(r);
                calculateHisBusy(r);
            });
            appointmentService.getAppointments(user.userId).then(r => {
                setMyAppointments(r);
                calculateMyBusy(r);
            });
        }
    }, []);

    const checkTimezone = (event) => {
        let eventData = JSON.parse(event.dataToComplete);
        let userData = JSON.parse(user.data);
        if(eventData.filter(dt => dt.type === 'Timezone').length > 0){
            let dt = eventData.filter(dt => dt.type === 'Timezone')[0];
            if(userData && userData[dt.name]){
                setMyTimezone(userData[dt.name]);
            }
        }
    };

    const calcLocalTime = (time) => {
        const initTimezone = timezones.filter(t => t.tzCode === timezone)[0];
        const finalTimezone = timezones.filter(t => t.tzCode === myTimezone)[0];
        let initMargin = parseInt(initTimezone.utc.split(':')[0]);
        let finMargin = parseInt(finalTimezone.utc.split(':')[0]);
        let margin = initMargin - finMargin;
        let initTime = parseInt(time.split(':')[0]);
        let minutes = time.split(':')[1];
        return ((initTime + margin).toString() !== '24' ? (initTime + margin).toString() : '00') + ':' + minutes;
    }

    const calculateAvailability = (ev) => {
        let dateStart = new Date(ev.dateStart.split('T')[0]);
        let dateEnd = new Date(ev.dateEnd);
        let ds = [];
        ds.push(dds[dateStart.getDay()] + ' ' + months[dateStart.getMonth()] + ' ' + dateStart.getDate().toString().padStart(2, '0'));
        while(dateStart < dateEnd){
            dateStart.setDate(dateStart.getDate() + 1);
            ds.push(dds[dateStart.getDay()] + ' ' + months[dateStart.getMonth()] + ' ' + dateStart.getDate().toString().padStart(2, '0'));
        }
        setDays(ds);

        let hhs = MyLibrary.getHours(true).filter(h => h >= ev.timeStart && h <= ev.timeEnd);
        console.log(hhs)
        setHours(hhs);
        forceUpdate();
    };

    const calculateHisBusy = (aps) => {
        let busys = [];
        for(let i = 0; i < aps.length; i ++){
            if(aps[i].status !== 'canceled'){
                busys.push(aps[i].datetime)
            }
        }
        setUnavailableHours(busys);
    };

    const calculateMyBusy = (aps) => {
        let busys = [];
        for(let i = 0; i < aps.length; i ++){
            if(aps[i].status !== 'canceled'){
                busys.push(aps[i].datetime);
            }
        }
        setUnavailableHours2(busys);
    };

    const requestMeeting = () => {
        appointmentService.requestAppointment(otherUserId, requesting, message).then(r => {
            appointmentService.getAppointments(otherUserId).then(r => {
                setAppointments(r);
                calculateHisBusy(r);
            });
            appointmentService.getAppointments(user.userId).then(r => {
                setMyAppointments(r);
                calculateMyBusy(r);
            });
            setShowMeetingModal(false);
            setRequesting('');
            setMessage('');
        }).catch(e => console.log(e));
    };


    return <> 
        <Row style={{ margin: '10px' }}>
            {participant && participant.userId > 0 && <>
                <Col md="12" style={{ marginLeft: '10px', marginTop: '25px', marginBottom: '30px' }}>
                    {   
                        myTimezone && !showMyTimezone && myTimezone !== timezone && <span className="text-primary float-end">
                            Hours are displayed in {timezone} timezone. <Button color="link" onClick={() => setShowMyTimezone(true)}>
                                Show in my timezone ({myTimezone})
                            </Button>
                        </span>
                    }
                    {   
                        myTimezone && showMyTimezone && myTimezone !== timezone && <span className="text-primary float-end">
                            Hours are displayed in {myTimezone} timezone. <Button color="link" onClick={() => setShowMyTimezone(false)}>
                                Show in default timezone ({timezone})
                            </Button>
                        </span>
                    }
                    <h4 className="text-primary bold" style={{fontWeight: '900', fontSize: '28px'}}>{ participant.name } &gt; Meetings</h4>
                </Col>
                <Col md="3">
                    <Card style={{padding: '20px'}}>
                        <div className="text-center">
                            <img src="/profile2.png" alt="profile" style={{ width: '100%' }} />
                        </div>
                        <h4>{participant.name}</h4>
                        <p>{participant.roleInCompany}</p>
                        <br />
                        <h3 className="text-warning">{participant.company.title}</h3>
                        <h6>{participant.company.city}, {participant.company.country}</h6>
                    </Card>
                </Col>
                <Col md="9">

                    <Card style={{padding: '20px'}}>
                        {
                            needToCompleteData && <Alert color="danger">
                                You need to complete your profile to arrange a meeting
                            </Alert>
                        }
                        {!needToCompleteData && 
                        <Table borderless>
                            <thead>
                                <tr>
                                    <th className="text-primary"></th>
                                    {
                                        days.map(d => <th key={d} className="text-primary">{d}</th>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    hours.map((h, i) => <tr key={v4()}>
                                        <td className="align-middle text-primary">{!showMyTimezone && h}{showMyTimezone && calcLocalTime(h)}</td>
                                        {
                                            days.map(d => {
                                                if(unavailableHours2.includes(d + ' ' + h)){
                                                    return <th key={d} ><Button color="danger" disabled style={{width: '100%'}} size="sm">You are Not Available</Button></th>
                                                }
                                                else if(unavailableHours.includes(d + ' ' + h)){
                                                    return <th key={d} ><Button color="warning" disabled style={{width: '100%'}} size="sm">Not Available</Button></th>
                                                }
                                                else{
                                                    return <th key={d} ><Button color="outline-secondary" style={{width: '100%'}} size="sm"
                                                        onClick={() => {
                                                            setRequesting(d + ' ' + h);
                                                            toggleMeetingModal();
                                                        }}>Available</Button></th>
                                                }
                                            })
                                        }
                                    </tr>)
                                }
                            </tbody>
                        </Table>
                        }
                    </Card>
                </Col>
            </>
            }
        </Row>
        <Modal isOpen={showMeetingModal} toggle={toggleMeetingModal} className="modal-lg">
            <ModalHeader toggle={toggleMeetingModal}>Arrange Meeting for {requesting}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Write a message</Label>
                    <Input type="textarea" onChange={e => setMessage(e.target.value)}/>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggleMeetingModal}>Cancel</Button>
                <Button color="primary" onClick={requestMeeting}>Request</Button>
            </ModalFooter>
        </Modal>
    </>
};

export default ArrangeMeeting;

